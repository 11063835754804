import React, { useState,useEffect  } from "react";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { useNavigate } from "react-router-dom";
import { AuthToken, BASE_URL } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import zIndex from "@mui/material/styles/zIndex";

function Inquiry(){
    const[Rdata, setRdata] = useState("noloader");
    const[StudentName ,setStudentName ]=useState("");
    const[EmailID ,setEmailID ]=useState("");
    const[Mobile ,setMobile ]=useState();
    const[OptionalSubject ,setOptionalSubject ]=useState("");
    const[ModeofExam ,setModeofExam ]=useState("");
    const[TargetYear ,setTargetYear ]=useState("");
    const[ApplyingFor ,setApplyingFor ]=useState("");
    const[OTP ,setOTP]=useState("");
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (ID) => {
       
        setShow(true);
    }
        


    const handlesubmit=(e)=>{
        e.preventDefault();
        const empdata= {AuthToken,
            StudentName,
            EmailID ,
            Mobile ,
            OptionalSubject ,
            ModeofExam ,
            TargetYear,
            ApplyingFor
        };
        if(StudentName == "")
        {
            toast.error("StudentName is required");
        }
        if(EmailID  == "")
        {
            toast.error("EmailID  is required");
        }
        if(!isValid){
            toast.error('Please use valid gmail address');
        }
        if(Mobile  == "")
        {
            toast.error("Mobile is required");
        }
        if(OptionalSubject  == "")
        {
            toast.error("Optional subject is required");
        }
        if(ModeofExam  == "")
        {
            toast.error("Mode of Exam  of exam is required");
        }
        if(TargetYear == "")
        {
            toast.error("Target year is required");
        }
        if(ApplyingFor == "")
        {
            toast.error("ApplyingFor is required");
        }
         
        if(isValid && StudentName != "" && EmailID  != "" && Mobile  !="" && OptionalSubject !="" && ModeofExam !="" && TargetYear!="" && ApplyingFor!="")
        {
          setRdata("showloader");
          fetch(BASE_URL+"/Account/AddStudentInquiryDetails",{
              method:"POST",
              headers:{
                  "Content-Type": "application/json",
                },
              body:JSON.stringify(empdata)
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.IsCallSuccessful) {
              setRdata("noloader");   
              navigate('/thank-you');         
              //toast.success(data.SuccessMessage);
            }
            else{
                setRdata("noloader");
                toast.error(data.ErrorMessage);
            }
          });
        }
    }


    const handleOTPChange = (event) => {
      setOTP(event.target.value);

      if (event.target.value.length > 4) {
        event.target.value = event.target.value.slice(0, 4);
        setOTP(event.target.value);
      }
    };

    const handleOTPVerification=(e)=>{
      e.preventDefault();
      const empdata= {AuthToken,
          Mobile ,
          OTP
      };
      if(OTP  == "")
      {
          toast.error("OTP  is required");
      }
      if(OTP != ""){
        setRdata("showloader");
        fetch(BASE_URL+"/Account/VerifyInquiryOTP",{
            method:"POST",
            headers:{
                "Content-Type": "application/json",
              },
            body:JSON.stringify(empdata)
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.IsCallSuccessful) {
            setRdata("noloader");
            if(data.IsOTPVerified === "Y"){
              setShow(false);
              setEmailID(data.EmailID);
              setStudentName(data.StudentName);
              setIsValid(/^[^\s@]+@gmail\.com$/.test(data.EmailID));
              toast.success(data.SuccessMessage);
            }
            else{
              toast.error(data.ErrorMessage);
            }
          }
          else{
              setRdata("noloader");
              toast.error(data.ErrorMessage);
          }
        });
      }
       
  }

    const Clear = () => {
        setStudentName("");
        setEmailID ("");
        setMobile ("");
        setOptionalSubject ("");
        setModeofExam("");
        setTargetYear("");
    };

    const targetyears = [
        {
            value: '2024',
            label: '2024',
          },
          {
            value: '2025',
            label: '2025',
          },
          {
            value: '2026',
            label: '2026',
          },
    ];

    const applyingfor = [
      {
        value: 'ScholarshipTest',
        label: 'Scholarship Test',
      },
      {
        value: 'AllIndiaPrelimsMockTest',
        label: 'All India Prelims Mock Test',
      },
      {
        value: 'Both',
        label: 'Both',
      },

    
    ];

    const exammodes = [     
        {
          value: 'Online',
          label: 'Online',
        },
        {
          value: 'Delhi',
          label: 'Delhi',
        },
        {
            value: 'Bengaluru',
            label: 'Bengaluru',
          },
          // {
          //   value: 'Bhopal',
          //   label: 'Bhopal',
          // },
          {
            value: 'Bhubaneswar',
            label: 'Bhubaneswar',
          },
          // {
          //   value: 'Dehradun',
          //   label: 'Dehradun',
          // },
          {
            value: 'Hyderabad',
            label: 'Hyderabad',
          },
          // {
          //   value: 'Jaipur',
          //   label: 'Jaipur',
          // },
          {
            value: 'Jammu',
            label: 'Jammu',
          },
          // {
          //   value: 'Kolhapur',
          //   label: 'Kolhapur',
          // },
          {
            value: 'Lucknow',
            label: 'Lucknow',
          },
          // {
          //   value: 'Mumbai',
          //   label: 'Mumbai',
          // },
          // {
          //   value: 'Patna',
          //   label: 'Patna',
          // },
          {
            value: 'Pune',
            label: 'Pune',
          },
          {
            value: 'Varanasi',
            label: 'Varanasi',
          },
      ];

      const subjects = [     
        {
          value: 'PSIR',
          label: 'PSIR',
        },
        {
          value: 'Sociology',
          label: 'Sociology',
        },
        {
            value: 'Anthropology',
            label: 'Anthropology',
          },
          {
            value: 'History',
            label: 'History',
          },
          {
            value: 'Other',
            label: 'Other',
          },
      ];
    
        const handlePhoneChange = (event) => {
            setMobile(event.target.value);
     
            if (event.target.value.length > 10) {
              event.target.value = event.target.value.slice(0, 10);
              setMobile(event.target.value);
            }
          };

          



          const CheckMobile = () => {
            setRdata("showloader");
                fetch(BASE_URL+"/Account/CheckMobileExistsandSendOTP", {
                    method: "POST",
                    body: JSON.stringify({AuthToken, Mobile}),
                    headers: {
                    "Content-Type": "application/json",
                    },
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.IsCallSuccessful === true) {
                        setRdata("noloader");
                        handleShow(true);
                        //show otp pop up
                    }
                    else{
                        setRdata("noloader");
                        //handleShow(true);
                        setMobile('');
                        toast.error(data.ErrorMessage);
                    }
                });
          }

          const [isValid, setIsValid] = useState(false);

          const handleEmailChange = event => {
              const { value } = event.target;
              setEmailID(value);
              setIsValid(/^[^\s@]+@gmail\.com$/.test(value));
            };

    
    return (
        <form className="App-logo"> 
        <div className="nk-app-root">
            <div className="nk-main">
                <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                               
                                <div className="card card-bordered">    
                                    <div className="card-inner card-inner-lg">

                                    
                                        

                                        <div className="brand-logo pb-4 text-center">
                                            <img width={75} className="logo" src="Images/srlogo.png" alt="logo" />
                                        </div>

                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                                <h6 className="nk-block-title" style={{fontSize:"medium"}}>Registration for All India Prelims Mock Test</h6>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                            <TextField id="txtLPhone" size="small" label="Mobile Number(10 digits only)*" value={Mobile}  onChange={handlePhoneChange} onBlur={CheckMobile} className="form-control rounded" name="Mobile " variant="outlined" inputProps={{ maxLength: 10, inputMode: 'numeric',type: 'number', autoComplete: 'new-password'}} />
                                            </div>
                                        </div>
                                        

                                        <div className="form-group">
                                            <div className="form-control-wrap">   
                                           
                                                 <TextField id="txtName" size="small" label="Name*" value={StudentName} onChange={e=>setStudentName(e.target.value)} className="form-control rounded" name="StudentName" type="text"  maxLength="100" variant="outlined" inputProps={{ maxLength: 100 }} />                                              
                                                
                                            </div>                                           
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">        
                                                <TextField id="txtEmail" size="small" label ="EmailID*"  value={EmailID } onChange={handleEmailChange} className="form-control rounded" name="EmailID " type="text" variant="outlined" inputProps={{ maxLength: 100 }}/>                                                 
                                            </div>                                           
                                        </div>


                                        

                                        <div className="form-group">
                                            <div className="form-control-wrap">


                                            <TextField
                                                    id="ddlOptional"
                                                    className="form-control rounded"
                                                    size="small"
                                                    onChange={e=>setOptionalSubject(e.target.value)}
                                                    select
                                                    label="Your Optional*"
                                                    // defaultValue="Male"
                                                >
                                                {subjects.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))}
                                                </TextField>

                                            
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">

                                            <TextField
                                                    id="ddlMode"
                                                    className="form-control rounded"
                                                    size="small"
                                                    onChange={e=>setModeofExam(e.target.value)}
                                                    select
                                                    label="Exam Center*"
                                                    // defaultValue="Male"
                                                >
                                                {exammodes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))}
                                                </TextField>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">

                                            <TextField
                                                    id="ddlApply"
                                                    className="form-control rounded"
                                                    size="small"
                                                    onChange={e=>setApplyingFor(e.target.value)}
                                                    select
                                                    label="Test Applying for*"
                                                    // defaultValue="Male"
                                                >
                                                {applyingfor.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))}
                                                </TextField>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                            <TextField
                                                    id="ddlTarget"
                                                    className="form-control rounded"
                                                    size="small"
                                                    onChange={e=>setTargetYear(e.target.value)}
                                                    select
                                                    label="Target Year*"
                                                    // defaultValue="Male"
                                                >
                                                {targetyears.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))}
                                                </TextField>
                                            </div>
                                        </div>



                                        <div style={{clear:"both"}}></div>
                                        <div className="form-group">
                                                <button onClick={handlesubmit}  className="btn btn-lg btn-primary btn-block">Register</button>
                                                <ToastContainer />
                                            
                                            </div>

                                            {/* <div className="form-group">
                                                <button onClick={Clear}  className="btn btn-lg btn-primary btn-block">Clear</button>
                                                <ToastContainer />
                                            </div> */}
                                            
                                            {Rdata == "showloader"?
                                                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>    :""
                                            }
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>


                    <Modal size="sm" show={show} onHide={handleClose} style={{position:"fixed", backdrop:"static"}} animation={false}>
                          <Modal.Header>
                            <Modal.Title>OTP Verification</Modal.Title>
                                      {/* <div>
                                        <img
                                                  src='Images/close.png'
                                                  width="30"
                                                  height="30"
                                                  marginTop="14px"
                                                  textAlign="center"
                                                  className="d-inline-block align-top"
                                                  alt="Close"
                                                  style={{backgroundColor:"white",textAlign:"center",marginLeft:"120px", cursor:"pointer"}}
                                                  onClick={handleClose}
                                                  
                                                />
                                        </div> */}
                          </Modal.Header>
                          <Modal.Body>
                                     
                            <div className="form-group">
                                <div className="form-control-wrap">        
                                    <TextField id="txtOTP" size="small" label="Enter OTP*" value={OTP}  onChange={handleOTPChange} className="form-control rounded" name="OTP " variant="outlined" inputProps={{ maxLength: 4, inputMode: 'numeric',type: 'number', autoComplete: 'new-password'}} />
                                </div>                                           
                            </div>

                                   
                          </Modal.Body>
                          <Modal.Footer>

                          <button onClick={handleOTPVerification}  className="btn btn-lg btn-primary btn-block">Verify OTP</button>

                           
                            {/* <Button variant="primary" onClick={() => handleUpdate()}>
                              Verify OTP
                            </Button> */}
                          </Modal.Footer>
                        </Modal>


                </div>
            </div>
        
            </form>
            );
}

export default Inquiry;