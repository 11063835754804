import React from 'react';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import Login from './Component/Login';
import VerifyOTP from './Component/VerifyOTP';
import StudentDetails from './Component/StudentDetails';
import Instruction from './Component/Instruction';
import MockTest from './Component/MockTest';
import TestResult from './Component/TestResult';
import Inquiry from './Component/Inquiry';
import ThankYou from './Component/ThankYou';
 
function App() {


  return (
    <div className='col-md-12'>

      <Router>
        <Routes>
        { <Route path="/" element={<Inquiry/>}/> }
        {/* {  <Route exact path="/Inquiry" element={<Inquiry/>}/>  } */}
          <Route path="/login" element={<Login/>}/>
          <Route path="/verify-otp" element={<VerifyOTP/>}/>
          <Route path="/student-details" element={<StudentDetails/>}/>
          <Route path="/instruction" element={<Instruction/>}/>
          <Route path="/mock-test" element={<MockTest/>}/>
          <Route path="/test-result" element={<TestResult/>}/>
          <Route path="/thank-you" element={<ThankYou/>}/>
         </Routes>
      </Router>  
    </div>
     
  );
}

export default App;
