import React, { useState,useEffect  } from "react";
import Navbar from './Navbar';
import { Card } from "react-bootstrap";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { AuthToken, BASE_URL } from "../Constants";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { zeroPad } from "react-countdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const MockTest = ()=>{     
    const[MockTestID, setMockTestID] = useState(13);
    //const[StudentID, setStudentID] = useState(5);
    const[CurrentQuestion, setCurrentQuestion] = useState(0);
    const[Rdata, setRdata] = useState("noloader");
    const[Duration, setDuration] = useState(100000);
    const[QuestionsList, setQuestionsList] = useState([]);
    const[QuestionsAnswers, setQuestionsAnswers] = useState([]);
    const[AttemptCount, setAttemptCount] = useState(0);
    const[ReviewCount, setReviewCount] = useState(0);
    const[SkipCount, setSkipCount] = useState(0);
    const[selectedOption, setselectedOption] = useState(null);
    
    const renderer = ({ hours, minutes, seconds }) => {
        return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
      };
    const[Questions, setQuestions] = useState(null);
    const navigate = useNavigate();

    //This is for countdown timer
    const clientTime = Date.now();

    
    //Disable Back Button//
    var ctrlKeyDown = false;
    useEffect(() => {
        const handleBackButton = (e) => {
          e.preventDefault();
          navigate('/login');
        };
    
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);

    //Disable Refresh and ctrl R//


    function my_onkeydown_handler( e ) {
        switch (e.keyCode) {
            case 116 : // 'F5'
                e.preventDefault();
                e.keyCode = 0;
                window.status = "F5 disabled";
                break;
        }

        if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && ctrlKeyDown)) {
            // Pressing F5 or Ctrl+R
            e.preventDefault();
        } else if ((e.which || e.keyCode) == 17) {
            // Pressing  only Ctrl
            ctrlKeyDown = true;
        }
    }
    document.addEventListener("keydown", my_onkeydown_handler);

    document.onkeydown = function(event){
        alert(event.keyCode);
        switch (event.keyCode){            
              case 116 : //F5 button
                  event.returnValue = false;
                  event.keyCode = 0;
                  return false;
              case 82 : //R button
                  if (event.ctrlKey){ 
                      event.returnValue = false;
                      event.keyCode = 0;
                      return false;
                  }
          }
      }
      //window.onbeforeunload = function () {handleSubmitTest();}
      window.onbeforeunload = ()=>handleSubmitTest();

      //window.addEventListener('beforeunload', handleSubmitTest);
    
    //


   
    //Disable Back Button//
    document.addEventListener('contextmenu', (e) => e.preventDefault());

        function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
        }

        document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
            e.keyCode === 123 ||
            ctrlShiftKey(e, 'I') ||
            ctrlShiftKey(e, 'J') ||
            ctrlShiftKey(e, 'C') ||
            (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
            return false;
        };
    useEffect(() => {
        const handleBackButton = (e) => {
          e.preventDefault();
          navigate('/mock-test');
        };
    
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);

    //Disable Back Button//



    const NavigateTest = async (event) => {
        event.preventDefault(); 
       // navigate('/mock-test');    
      }
    
   
    function HandleTimeronClik()
    {
        setDuration(Duration-1000); 
    }
    function BindQuestion(srqid){      
        //setDuration((Duration)-(seconds*1000));          
        setselectedOption(null);
        
        setCurrentQuestion(srqid);
        var QArray = [];        
        QArray = QuestionsList[srqid];
        document.getElementById("divQ").innerHTML = QArray.Question;
        document.getElementById("qnumber").innerHTML = parseInt(QArray.srqid)+1;
        const isFound = QuestionsAnswers.some(element => {
            if (element.QuestionID === QArray.QuestionID) {
                console.log(element.QAnswer,"Clicked QAnswer");
                setselectedOption(element.QAnswer);
              return true;
            }        
            return false;
          });

        // if(isFound){
        //     var AArray = [];   
        //     AArray = QuestionsAnswers[srqid];
        //     setselectedOption(AArray.QAnswer)
        // }        
        document.getElementById("lblOption1").innerHTML = QArray.Option1;
        document.getElementById("lblOption2").innerHTML = QArray.Option2;
        document.getElementById("lblOption3").innerHTML = QArray.Option3;
        document.getElementById("lblOption4").innerHTML = QArray.Option4;
        if(srqid == 0){
            document.getElementById("btnPre").disabled = true;
        }
        else{
            document.getElementById("btnPre").disabled = false;
        }    
        if(srqid == QuestionsList.length){
            document.getElementById("btnNext").disabled = true;
        }
        else{
            document.getElementById("btnNext").disabled = false;
        }            
    }

    function handleOnChange(e) {
        setselectedOption(e.target.value);
      }

    function AttemptQuestion(srqid)
    {        
        if(selectedOption){
        var QArray = [];        
        QArray = QuestionsList[srqid];
        const isFound = QuestionsAnswers.some(element => {
            if (element.QuestionID === QArray.QuestionID) {
              return true;
            }        
            return false;
          });

        if(isFound){
            RemoveAnswerformArray(srqid);
            QuestionsAnswers.splice(srqid,0,{
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : selectedOption,
                AMode:"Attempt",
            });        
            // QuestionsAnswers.push({
            //     srqid : srqid,
            //     QuestionID : QArray.QuestionID,
            //     QAnswer : selectedOption,
            //     AMode:"Attempt",
            // });
        }
        else{
            QuestionsAnswers.push({
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : selectedOption,
                AMode:"Attempt",
            });
        }
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.backgroundColor="#1ee0ac";
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.color="#fff";
        console.log(QuestionsAnswers);
        UpdateCounts();
        if((srqid+1) == QuestionsList.length){
            BindQuestion(srqid);
            //document.getElementById("btnNext").disabled = true;
        }
        else{
            document.getElementById("btnNext").disabled = false;
            BindQuestion(srqid+1);
        }        
        
    }
    else{
        toast.error("Please select answer");
    }
}

    function ReviewQuestion(srqid)
    {        
        if(selectedOption){
        var QArray = [];        
        QArray = QuestionsList[srqid];
        const isFound = QuestionsAnswers.some(element => {
            if (element.QuestionID === QArray.QuestionID) {
              return true;
            }        
            return false;
          });

        if(isFound)
        {
            RemoveAnswerformArray(srqid);
            QuestionsAnswers.splice(srqid,0,{
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : selectedOption,
                AMode:"Review",
            });       
            // QuestionsAnswers.push({
            //     srqid : srqid,
            //     QuestionID : QArray.QuestionID,
            //     QAnswer : selectedOption,
            //     AMode:"Review",
            // });
        }
        else{
            QuestionsAnswers.push({
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : selectedOption,
                AMode:"Review",
            });
        }
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.backgroundColor="#09c2de";
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.color="#fff";
        console.log(QuestionsAnswers);
        UpdateCounts();
        BindQuestion(srqid+1);
    }
    else{
       toast.error("Please select option")
    }
    }

    function SkipQuestion(srqid)
    {        
        var QArray = [];        
        QArray = QuestionsList[srqid];
        const isFound = QuestionsAnswers.some(element => {
            if (element.QuestionID === QArray.QuestionID) {
              return true;
            }        
            return false;
          });

        if(isFound)
        {
            RemoveAnswerformArray(srqid);
            QuestionsAnswers.splice(srqid,0,{
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : '',
                AMode:"Skip",
            });      
            // QuestionsAnswers.push({
            //     srqid : srqid,
            //     QuestionID : QArray.QuestionID,
            //     QAnswer : '',
            //     AMode:"Skip",
            // });
        }
        else{
            QuestionsAnswers.push({
                srqid : srqid,
                QuestionID : QArray.QuestionID,
                QAnswer : '',
                AMode:"Skip",
            });
        }
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.backgroundColor="#e85347";
        document.getElementById("Qid_"+(CurrentQuestion+1)).style.color="#fff";
        console.log(QuestionsAnswers);
        UpdateCounts();
        BindQuestion(srqid+1);
    }

    function RemoveAnswerformArray(srqid)
    {
        var AArray = [];   
        AArray = QuestionsAnswers[srqid];
        var index = QuestionsAnswers.indexOf(AArray);
        QuestionsAnswers.splice(index, 1);
    }

    function UpdateCounts(){
        var ACount = 0; var RCount =0; var SCount = 0;
        for (let i = 0; i < QuestionsAnswers.length; i++){
            var AArray = [];   
            AArray = QuestionsAnswers[i];
            if(AArray.AMode === "Attempt")
            {
                ACount += 1;                
            }
            else if(AArray.AMode === "Review")
            {
                RCount += 1;                
            }
            else{
                SCount += 1;                
            }
        }
        setAttemptCount(ACount);
        setReviewCount(RCount);
        setSkipCount(SCount);

    }


      //Get Test Questions
     
      useEffect(() => {       
        let StudentID = localStorage.getItem("StudentID");
        setRdata("showloader");
        fetch(BASE_URL+"/mocktest/GetTestQuestions", {
            method: "POST",
            body: JSON.stringify({ MockTestID,AuthToken,StudentID }),
            headers: {
              "Content-Type": "application/json",
            },
           })
            .then((response) => response.json())
            .then((data) => {
              if (data.IsCallSuccessful) {  
                
                if(data.TestAttempts > 0){
                    navigate('/test-result');
                }
                else{
                    QuestionsList.length = 0;
                    for (let i = 0; i < data.MoctTestQuestions.length; i++) {                   
                        QuestionsList.push({
                            srqid : i,
                            QuestionID : data.MoctTestQuestions[i].QuestionID,
                            Question : data.MoctTestQuestions[i].Question,
                            Option1 : data.MoctTestQuestions[i].Option1,
                            Option2 : data.MoctTestQuestions[i].Option2,
                            Option3 : data.MoctTestQuestions[i].Option3,
                            Option4 : data.MoctTestQuestions[i].Option4,
                        });
                    }                
                    BindQuestion(0);     
                    setRdata("noloader");
                    setDuration(data.MockTest.Duration*60000);
                    setQuestions(data.MockTest.Questions); 
                }
                   
                              
                
              } else {
                setRdata("noloader");
                toast.error(data.ErrorMessage);
              }
            });
          
    },[]);

    

    const TQs = [];

    for (let i = 0; i < QuestionsList.length; i++) {
       // TQs.push(<option value={i}>{i}</option>);
       const qid = 'Qid_'+`${i+1}`;
       TQs.push(<div style={{cursor:"pointer"}} onClick={() => BindQuestion(i)}  id={qid} className="question-number-div"><p>{i+1}</p></div>);
    }

    const handleSubmitTest = async (event) => {
        let StudentID = localStorage.getItem("StudentID");
        setRdata("showloader");
        var StudentAnswersList = QuestionsAnswers;
        fetch(BASE_URL+"/mocktest/SubmitTestQuestionAnswer", {
            method: "POST",
            body: JSON.stringify({ MockTestID,AuthToken,StudentID,StudentAnswersList}),
            headers: {
              "Content-Type": "application/json",
            },
           })
           .then((response) => response.json())
           .then((data) => {
            if (data.IsCallSuccessful) {
                navigate('/test-result');
            }
            else{
                setRdata("noloader");
                toast.error(data.ErrorMessage);
            }
           })
    }


  const [showse, setShowse] = useState(false);
  const handleCloseses = () => setShowse(false);

    const handleShowse = () => {
      setShowse(true);
    };




    return(
        <>
        <Navbar/>   
        
        <div >   
             
        <form> 
        <div className="nk-app-root">
            <div className="nk-main">
                <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">

                        <div className="card card-bordered">
                        <div className="row">
                            <div className="col-xl-12">
                            <div className="col-xl-9" style={{padding:"20px", borderBottom:"1px solid #ccc", float:"left"}}>
                                    <h4>All India CSAT Mock Test (05 Jan 2025, 09:30 AM to 06:00 PM)</h4>
                                </div>
                                <div className="col-xl-3" style={{padding:"20px", borderBottom:"1px solid #ccc", float:"left", marginTop:"4px"}}>
                                    <div className="countdown-div">
                                        <h5>Time Remaning: <Countdown date={clientTime + Duration} onTick={HandleTimeronClik} onComplete={handleSubmitTest}  renderer={renderer}> 
                                                                   
                                            </Countdown></h5>
                                    </div>
                                </div>
                            </div>
                                
                            </div>

                            <div className="row">
                            <div className="col-xl-8" style={{borderRight:"1px solid #ccc"}}>
                                
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 que-ans-col">
                                            <div className="que-ans-div">                                                <div className="que-div">
                                                    <div>
                                                        <b>Question <span id="qnumber"></span>:</b>
                                                    </div>
                                                    <div className="inner-div" id="divQ">
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ans-div">
                                                    <div>
                                                        <b>Options:</b>
                                                    </div>
                                                <div className="inner-div clearfix">
                                                    <div>
                                                        <label className="radio">
                                                            <input type="radio" checked={selectedOption === 'Option1'} value="Option1" onChange={handleOnChange} name="answerOption" data-parsley-multiple="answerOption" /> A. <span id="lblOption1"></span>                                                    
                                                        </label> 
                                                    </div>
                                                   
                                                    <div>
                                                        <label className="radio">
                                                            <input type="radio" checked={selectedOption === 'Option2'} value="Option2" onChange={handleOnChange} name="answerOption" data-parsley-multiple="answerOption" /> B. <span id="lblOption2"></span>                                                    
                                                        </label> 
                                                    </div>

                                                    <div>
                                                        <label className="radio">
                                                            <input type="radio" checked={selectedOption === 'Option3'} value="Option3" onChange={handleOnChange} name="answerOption" data-parsley-multiple="answerOption" /> C. <span id="lblOption3"></span>                                                    
                                                        </label> 
                                                    </div>

                                                    <div>
                                                        <label className="radio">
                                                            <input type="radio" checked={selectedOption === 'Option4'} value="Option4" onChange={handleOnChange} name="answerOption" data-parsley-multiple="answerOption" /> D. <span id="lblOption4"></span>                                                    
                                                        </label> 
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{clear:"both"}}></div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 button-col" style={{paddingLeft:"0px"}}>
                                                <button type="button" onClick={() => BindQuestion(CurrentQuestion-1)} className="btn btn-primary" id="btnPre"> Previous</button>&nbsp;&nbsp;
                                                <button type="button" onClick={() => SkipQuestion(CurrentQuestion)} className="btn btn-danger" id="btnSkip"> Skip</button>&nbsp;&nbsp; 
                                                <button type="button" onClick={() => ReviewQuestion(CurrentQuestion)} className="btn btn-info" id="btnRev"> Review</button>&nbsp;&nbsp;
                                                <button type="button" onClick={() => AttemptQuestion(CurrentQuestion)} className="btn btn-success" id="btnNext"> Next</button>&nbsp;&nbsp;
                                                <button type="button" onClick={() => handleShowse()} className="btn btn-success" id="btnSubmit" style={{float:"right"}}> Submit Test</button><br/>
                                                
                                                <ToastContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                             </div>

                            <div className="col-xl-4">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="row" style={{borderBottom:"1px solid #ccc", marginLeft:"-29px"}}>
                                        <div className="text-center col-lg-4 col-md-4 col-sm-4 col-xs-4 test-status-col attempted">
                                            <p>Attempted</p>
                                            <label id="Attcnt">{AttemptCount}</label>
                                        </div>
                                        <div className="text-center col-lg-4 col-md-4 col-sm-4 col-xs-4 test-status-col attempted">
                                                <p>Review</p>
                                                <label id="Reviewcnt">{ReviewCount}</label>
                                        </div>
                                        <div className="text-center col-lg-4 col-md-4 col-sm-4 col-xs-4 test-status-col attempted">
                                                <p>Skip</p>
                                                <label id="Skipcnt">{SkipCount}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 questions-col">
                                        <h5>Questions</h5>
                                        <div className="questions-div clearfix">                                        
                                            {TQs}
                                        </div>
                                    </div>
                                </div>                              
                            </div>


                            </div>
                        </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        
            </form>

            {Rdata === "showloader"?
                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                <CircularProgress color="inherit" />
            </Backdrop>    :""
            }
        </div >



                        <Modal  show={showse} onHide={handleCloseses} animation={false}>
                          <Modal.Header>
                            <Modal.Title>Submit Test</Modal.Title>
                            <div>
                              <img
                                src='Images/close.png'
                                width="30"
                                height="30"
                                marginTop="14px"
                                textAlign="center"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                                style={{ backgroundColor: "white", textAlign: "center", marginLeft: "120px", cursor: "pointer" }}
                                onClick={handleCloseses}
                              />
                            </div>
                          </Modal.Header>
                          <Modal.Body style={{textAlign:"center",fontSize:"18px"}}>Are you sure want to submit ?</Modal.Body>
                          <Modal.Footer>
                            <div style={{marginRight:"38%"}}>
                            <Button variant="secondary" onClick={handleCloseses}>
                              No
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => handleSubmitTest()}>
                              Yes
                            </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>  





        </>
    )
}

export default MockTest;